import { FilePdfOutlined, HistoryOutlined, SwapOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Result, Skeleton } from 'antd';
import React, { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Subscription } from 'rxjs';
import {
  HasButton,
  HasHorizontalMenu,
  HasIncidentLocationDescription,
  HasIncidentMedia,
  HasInvolvedPartiesDescription,
  HasInvolvedPartyDescription,
  HasReportingOwnerDescription,
  HasSpinner,
  HasText,
  Notification,
} from '../../components';
import { PermissionsContext } from '../../context';
import {
  Action,
  Attachment,
  FormLabel,
  Incident,
  IncidentSeverity,
  IncidentType,
  IncidentTypeLabel,
  Investigation,
  PermissionType,
  Photo,
  Review,
  RiskLevel,
  RiskLevelLabel,
  User,
  UserRole,
} from '../../models';
import {
  ActionAttachmentService,
  ActionService,
  EntityAction,
  FormService,
  IncidentAttachmentService,
  IncidentService,
  InvestigationAttachmentService,
  InvestigationService,
  PhotoService,
  ReviewAttachmentService,
  ReviewService,
  UserService,
} from '../../services';
import {
  ALLOWED_FILE_TYPES,
  COLORS,
  ErrorMessages,
  MAIN_REPORT_TYPES,
  MAIN_URLS,
  MenuItemWithKey,
  RISK_SCORE_COLOR,
  RW_ROLES,
  SuccessMessages,
} from '../../shared';
import {
  EventUtils,
  formatTextToTitlecase,
  getQueryParamValue,
  getUserRole,
  hasPermission,
  hasPermissionType,
  isObjectEmpty,
  isUserRoleEqualsTo,
} from '../../utils';
import { HasEventTypeSwap } from './event.type.swap';
import './incidents.scss';
import { HasActionDetails, HasEventPdfExport, HasIncidentDetails, HasInvestigationDetails } from './index';
import HasReviewDetails from './review';

interface IncidentPageProps extends RouteComponentProps<{ view: string; id: string }>, WithTranslation {}

interface IncidentPageState {
  loading: boolean;
  mediaLoading: boolean;
  incident: Incident;
  loadError: boolean;
  investigation: Investigation;
  review: Review;
  actions: Action[];
  photos: Photo[];
  incidentAttachments: Attachment[];
  investigationAttachments: Attachment[];
  actionAttachments: Attachment[];
  reviewAttachments: Attachment[];
  users: User[];
  formLabels: FormLabel;
  queryParams: URLSearchParams;
}

const REVIEW_EDIT_KEY = 'edit';

class HasIncidentPageWrapper extends React.Component<IncidentPageProps, IncidentPageState> {
  state = {
    loading: true,
    loadError: false,
    mediaLoading: true,
    incident: {} as Incident,
    investigation: {} as Investigation,
    review: {} as Review,
    actions: [] as Action[],
    photos: [] as Photo[],
    incidentAttachments: [] as Attachment[],
    investigationAttachments: [] as Attachment[],
    actionAttachments: [] as Attachment[],
    reviewAttachments: [] as Attachment[],
    users: [] as User[],
    formLabels: {} as FormLabel,
    queryParams: new URLSearchParams(this.props.location.search),
  };

  private subscriptions: Subscription[] = [];

  componentDidMount() {
    const { setPermissionsForUser } = this.context;
    setPermissionsForUser();
    this.subscriptions.push(
      InvestigationService.getInvestigationChangedListener().subscribe(async (event) => {
        switch (event.event) {
          case EntityAction.CREATE:
            await InvestigationService.create(event.entity);
            break;
          case EntityAction.UPDATE:
            await InvestigationService.update(event.entity);
            break;
          case EntityAction.DELETE:
            await InvestigationService.delete(event.entity.id);
            break;
        }
        this.getInvestigation();
      }),
      IncidentService.getIncidentChangedListener().subscribe(async (event) => {
        switch (event.event) {
          case EntityAction.UPDATE:
            try {
              IncidentService.update(event.entity).then(() => this.getIncident());
              Notification.success(SuccessMessages.INCIDENT_REPORT_UPDATE);
            } catch (error) {
              Notification.error(ErrorMessages.INCIDENT_REPORT_UPDATE(error));
            }
            break;
        }
      }),
      ActionService.getActionChangedListener().subscribe(async (event) => {
        switch (event.event) {
          case EntityAction.CREATE:
            try {
              await ActionService.create(event.entity);
              Notification.success(SuccessMessages.ACTION_CREATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.UPDATE:
            try {
              await ActionService.update(event.entity);
              Notification.success(SuccessMessages.ACTION_UPDATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.CHANGE_STATUS:
            try {
              await ActionService.changeStatus(event.entity);
              Notification.success(SuccessMessages.ACTION_UPDATE);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
          case EntityAction.DELETE:
            try {
              await ActionService.delete(event.entity.id!);
              Notification.success(SuccessMessages.ACTION_DELETE);
              this.props.history.push(`/events/action/${event.entity.incidentId}`);
            } catch (error) {
              Notification.error({ message: error.response.data.message });
            }
            break;
        }
        this.getActions();
      }),
      IncidentAttachmentService.getAttachmentChangedListener().subscribe(async (event) => {
        let shouldGetData = false;
        switch (event.event) {
          case EntityAction.CREATE:
            await IncidentAttachmentService.create(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.UPDATE:
            await IncidentAttachmentService.update(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.DELETE:
            try {
              await IncidentAttachmentService.delete(event.entity.id);
              Notification.success(SuccessMessages.ATTACHMENT_DELETE(event.entity.file.fileName));
              let incidentAttachments = this.state.incidentAttachments.filter(
                (attachment) => attachment.id !== event.entity.id
              );
              this.setState({ incidentAttachments });
            } catch (error) {
              Notification.error(ErrorMessages.ATTACHMENT_DELETE(error.response?.data.message));
            }
            break;
          case EntityAction.REFRESH:
            shouldGetData = true;
            break;
        }
        shouldGetData && (await this.getData());
      }),
      InvestigationAttachmentService.getAttachmentChangedListener().subscribe(async (event) => {
        let shouldGetData = false;
        switch (event.event) {
          case EntityAction.CREATE:
            await InvestigationAttachmentService.create(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.UPDATE:
            await InvestigationAttachmentService.update(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.DELETE:
            try {
              await InvestigationAttachmentService.delete(event.entity.id);
              Notification.success(SuccessMessages.ATTACHMENT_DELETE(event.entity.file.fileName));
              let investigationAttachments = this.state.investigationAttachments.filter(
                (attachment) => attachment.id !== event.entity.id
              );
              this.setState({ investigationAttachments });
            } catch (error) {
              Notification.error(ErrorMessages.ATTACHMENT_DELETE(error.response?.data.message));
            }
            break;
          case EntityAction.REFRESH:
            shouldGetData = true;
            break;
        }
        shouldGetData && (await this.getData());
      }),
      ReviewService.getReviewChangedListener().subscribe(async (event) => {
        try {
          switch (event.event) {
            case EntityAction.UPDATE:
              await ReviewService.update(event.entity).then(() => Notification.success(SuccessMessages.REVIEW_UPDATE));
              break;
            case EntityAction.REFRESH:
              await ReviewService.update(event.entity);
              break;
          }
        } catch (error) {
          Notification.error(ErrorMessages.REVIEW_UPDATE(error));
        }
        this.getReview();
      }),
      ActionAttachmentService.getAttachmentChangedListener().subscribe(async (event) => {
        let shouldGetData = false;
        switch (event.event) {
          case EntityAction.DELETE:
            try {
              await ActionAttachmentService.delete(event.entity.id);
              Notification.success(SuccessMessages.ATTACHMENT_DELETE(event.entity.file.fileName));
              this.setState({
                actionAttachments: this.state.actionAttachments.filter(
                  (attachment) => attachment.id !== event.entity.id
                ),
              });
            } catch (error) {
              Notification.error(ErrorMessages.ATTACHMENT_DELETE(error.response?.data.message));
            }
            break;
          case EntityAction.REFRESH:
            shouldGetData = true;
            break;
        }
        shouldGetData && (await this.getData());
      }),
      ReviewAttachmentService.getAttachmentChangedListener().subscribe(async (event) => {
        let shouldGetData = false;
        switch (event.event) {
          case EntityAction.CREATE:
            await ReviewAttachmentService.create(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.UPDATE:
            await ReviewAttachmentService.update(event.entity);
            shouldGetData = true;
            break;
          case EntityAction.DELETE:
            try {
              await ReviewAttachmentService.delete(event.entity.id);
              Notification.success(SuccessMessages.ATTACHMENT_DELETE(event.entity.file.fileName));
              this.setState({
                reviewAttachments: this.state.reviewAttachments.filter(
                  (attachment) => attachment.id !== event.entity.id
                ),
              });
            } catch (error) {
              Notification.error(ErrorMessages.ATTACHMENT_DELETE(error.response?.data.message));
            }
            break;
          case EntityAction.REFRESH:
            shouldGetData = true;
            break;
        }
        shouldGetData && (await this.getData());
      }),
      PhotoService.getPhotoChangedListener().subscribe(async (event) => await this.getData())
    );
    this.getData();
  }

  componentDidUpdate(prevProps: Readonly<RouteComponentProps<{ view: string; id: string }>>) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.setState({ queryParams: new URLSearchParams(location.search) });
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getData();
    }
  }

  getIncident = () => {
    IncidentService.getById(this.props.match.params.id).then((incidentResponse) =>
      this.setState({ incident: incidentResponse.data })
    );
  };

  getInvestigation = () => {
    InvestigationService.getByIncidentId(this.props.match.params.id).then((investigationResponse) =>
      this.setState({ investigation: investigationResponse.data })
    );
  };

  getReview = () => {
    ReviewService.getByIncidentId(this.props.match.params.id).then((reviewResponse) =>
      this.setState({ review: reviewResponse.data })
    );
  };

  getActions = () => {
    ActionService.getByIncidentId(this.props.match.params.id).then((actionResponse) =>
      this.setState({ actions: actionResponse.data })
    );
  };

  getData = async () => {
    this.setState({ loading: true });
    const incidentId = this.props.match.params.id;

    let incidentResponse, actionsResponse;
    try {
      [incidentResponse, actionsResponse] = await Promise.all([
        IncidentService.getById(incidentId),
        ActionService.getByIncidentId(incidentId),
      ]);
    } catch (error) {
      this.setState({ loadError: true });
      return;
    }

    const usersResponse = await UserService.getAllUsersForCompany(incidentResponse.data.company.id, NaN, false);
    const formLabelsResponse = await FormService.getFormLabels(incidentResponse.data.id);

    if (MAIN_REPORT_TYPES.includes(incidentResponse.data.type)) {
      const investigationResponse = await InvestigationService.getByIncidentId(incidentId);
      this.setState({ investigation: investigationResponse.data });
    } else {
      const reviewResponse = await ReviewService.getByIncidentId(incidentId);
      this.setState({ review: reviewResponse.data });
    }

    this.buildMenuConfig(incidentResponse.data);

    this.setState({
      incident: incidentResponse.data,
      actions: actionsResponse.data,
      users: usersResponse.data.filter((user) => !isUserRoleEqualsTo(UserRole.GUEST, user)),
      formLabels: formLabelsResponse.data,
      loading: false,
      loadError: false,
    });

    IncidentService.eventTypeChanged({ eventType: incidentResponse.data.type, eventId: incidentResponse.data.id });

    this.getIncidentMedia();
  };

  buildMenuConfig = (incident: Incident): MenuItemWithKey[] => {
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;
    let baseConfig = [
      {
        key: `/events/event/${id}`,
        title: formatTextToTitlecase(t('incidents.event')),
      },
      {
        key: `/events/action/${id}`,
        title: t('incidents.action'),
      },
    ];
    const userRole = getUserRole();
    if (userRole && RW_ROLES.includes(userRole as UserRole)) {
      if (MAIN_REPORT_TYPES.includes(incident.type)) {
        baseConfig.splice(1, 0, {
          key: `/events/investigation/${id}`,
          title: t('incidents.investigation'),
        });
      } else {
        baseConfig.splice(1, 0, {
          key: `/events/review/${id}`,
          title: t('incidents.review'),
        });
      }
    }
    return baseConfig;
  };

  getIncidentMedia = async () => {
    const { incident, investigation, review } = this.state;
    let photos = await this.getIncidentPhotos(incident);
    let incidentAttachments = await this.getIncidentAttachments(incident);
    let investigationAttachments = await this.getInvestigationAttachments(investigation);
    let actionAttachments = await this.getActionAttachments(incident.id);
    let reviewAttachments = [] as Attachment[];
    if (!MAIN_REPORT_TYPES.includes(incident.type)) {
      reviewAttachments = await this.getReviewAttachments(review);
    }
    this.setState({
      photos,
      incidentAttachments,
      investigationAttachments,
      actionAttachments,
      reviewAttachments,
      mediaLoading: false,
    });
  };

  getIncidentPhotos = async (incident: Incident): Promise<Photo[]> => {
    if (incident.photos) {
      const photoIds = incident.photos.map((photo) => photo.id);
      const photosResponse = await Promise.all(photoIds.map((id) => PhotoService.getPreviewById(id)));
      return photosResponse.map((photoResponse) => photoResponse.data);
    } else {
      return [] as Photo[];
    }
  };

  getIncidentAttachments = async (incident: Incident): Promise<Attachment[]> => {
    if (incident.attachments) {
      const attachmentIds = incident.attachments.map((attachment) => attachment.id);
      const attachmentsResponse = await Promise.all(attachmentIds.map((id) => IncidentAttachmentService.getById(id)));
      return attachmentsResponse.map((attachmentResponse) => attachmentResponse.data);
    } else {
      return [] as Attachment[];
    }
  };

  getInvestigationAttachments = async (investigation: Investigation): Promise<Attachment[]> => {
    if (investigation.attachments) {
      const attachmentIds = investigation.attachments.map((attachment) => attachment.id);
      const attachmentsResponse = await Promise.all(
        attachmentIds.map((id) => InvestigationAttachmentService.getById(id))
      );
      return attachmentsResponse.map((attachmentResponse) => attachmentResponse.data);
    } else {
      return [] as Attachment[];
    }
  };

  getReviewAttachments = async (review: Review): Promise<Attachment[]> => {
    if (review?.attachments) {
      const attachmentIds = review.attachments.map((attachment) => attachment.id);
      const attachmentsResponse = await Promise.all(attachmentIds.map((id) => ReviewAttachmentService.getById(id)));
      return attachmentsResponse.map((attachmentResponse) => attachmentResponse.data);
    } else {
      return [] as Attachment[];
    }
  };

  getActionAttachments = async (eventId: number): Promise<Attachment[]> => {
    return (await ActionAttachmentService.getAllForEvent(eventId)).data;
  };

  incidentHasAccessiblePhotos = (): boolean => {
    const { incident, incidentAttachments, investigationAttachments, actionAttachments, reviewAttachments } =
      this.state;
    if (incident.photos?.some((photo) => photo.isOwn || !photo.isPrivate)) {
      return true;
    }
    if (
      incidentAttachments?.some(
        (attachment) =>
          ALLOWED_FILE_TYPES.Image.includes(attachment.file.fileType) && (attachment.own || !attachment.private)
      )
    ) {
      return true;
    }
    if (
      MAIN_REPORT_TYPES.includes(incident.type) &&
      investigationAttachments?.some(
        (attachment) =>
          ALLOWED_FILE_TYPES.Image.includes(attachment.file.fileType) && (attachment.own || !attachment.private)
      )
    ) {
      return true;
    }
    if (
      !MAIN_REPORT_TYPES.includes(incident.type) &&
      reviewAttachments?.some(
        (attachment) =>
          ALLOWED_FILE_TYPES.Image.includes(attachment.file.fileType) && (attachment.own || !attachment.private)
      )
    ) {
      return true;
    }
    if (
      actionAttachments?.some(
        (attachment) =>
          ALLOWED_FILE_TYPES.Image.includes(attachment.file.fileType) && (attachment.own || !attachment.private)
      )
    ) {
      return true;
    }
    return false;
  };

  getHorizontalMenuItems = (): MenuItemWithKey[] => {
    const { incident } = this.state;
    const { permissions } = this.context;
    const {
      match: {
        params: { id },
      },
      t,
    } = this.props;
    const menuItems: MenuItemWithKey[] = [
      {
        key: `/events/export/${id}`,
        tooltip: t('pdfExport.title'),
        icon: <FilePdfOutlined className="align-middle mr-0" />,
      },
    ];
    const userRole = getUserRole();
    if (
      hasPermissionType(PermissionType.INCIDENTS_TYPE_SWAP, permissions) &&
      (userRole === UserRole.SUPER_ADMIN || userRole === UserRole.OWNER)
    ) {
      menuItems.push({
        key: `/events/swap/${id}`,
        tooltip: t('typeSwap.title'),
        icon: <SwapOutlined className="align-middle mr-0" />,
        disabled: incident.hidden,
      });
    }
    return menuItems;
  };

  getEventInformationBanners = (): JSX.Element | null => {
    const { t } = this.props;
    const { incident } = this.state;
    let banner: JSX.Element | null = null;
    if (
      (incident.swappedByName !== null && incident.swappedFromId !== null) ||
      incident.historicalUpload ||
      incident.severity
    ) {
      banner = (
        <Alert
          message={
            <div className="d-flex flex-column justify-content-center">
              {incident.swappedByName !== null && incident.swappedFromId !== null && (
                <div className="d-flex-inline align-items-center p-1">
                  <SwapOutlined className="align-middle mr-2" style={{ color: COLORS.GRAY_DARK, fontSize: 14 }} />
                  <HasText content={t('incidents.typeSwapped', { name: incident.swappedByName })} className="mr-1" />
                  <Link to={`/events/event/${incident.swappedFromId}`}>#{incident.swappedFromId}</Link>
                </div>
              )}
              {incident.historicalUpload && (
                <div className="d-flex-inline align-items-center p-1">
                  <HistoryOutlined className="align-middle mr-2" style={{ color: COLORS.GRAY_DARK, fontSize: 14 }} />
                  <HasText content={t('incidents.historicalUpload')} />
                </div>
              )}
              {incident.severity && (
                <div className="d-flex-inline align-items-center p-1">
                  <WarningOutlined className="align-middle mr-2" style={{ color: COLORS.GRAY_DARK, fontSize: 14 }} />
                  <HasText
                    content={t(
                      incident.severity === IncidentSeverity.SEVERE ? 'incidents.severeLong' : 'incidents.moderateLong'
                    )}
                  />
                </div>
              )}
            </div>
          }
          type="info"
          closable
          className="mb-2"
        />
      );
    }
    return banner;
  };

  getRiskMatrixBanner = (): JSX.Element | null => {
    const {
      t,
      match: {
        params: { view },
      },
    } = this.props;

    const { riskScore, riskLevel } = this.state.investigation;
    if (riskScore && riskLevel && this.state.investigation.formElements.riskMatrix) {
      const { riskMatrix } = this.state.investigation.formElements;
      const lastEntry = riskMatrix[riskMatrix.length - 1];
      const data = JSON.parse(lastEntry.value!);
      const impactIndex = EventUtils.getRiskImpactIndex(data.impactValue);
      const probabilityIndex = EventUtils.getRiskProbabilityIndex(data.probabilityValue);
      const riskColor = RISK_SCORE_COLOR[impactIndex! - 1][probabilityIndex! - 1];

      let banner: JSX.Element | null = null;

      if (MAIN_URLS.INVESTIGATION.includes(view)) {
        banner = (
          <Alert
            message={
              <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
                <HasText
                  content={
                    <div>
                      <HasText content={t('shared.riskMatrix.scoreMessage', `This event has a risk score of`)} />
                      <HasText style={{ color: riskColor, fontWeight: 600 }} content={`${' ' + data.riskScore}`} />
                      <span>{', '}</span>
                      <HasText content={t('shared.riskMatrix.level', 'level')} />
                      <HasText
                        style={{ color: riskColor, fontWeight: 600 }}
                        content={`${' ' + RiskLevelLabel?.[riskLevel as RiskLevel]?.(t)}`}
                      />
                      <span>{'. '}</span>
                      <HasText content={t('shared.riskMatrix.infoMessage', 'See the Risk Matrix entry below.')} />
                    </div>
                  }
                />
              </div>
            }
            type="info"
            closable
            className="mb-2"
          />
        );
      }
      return banner;
    } else {
      return null;
    }
  };

  getViewComponent = (): ReactNode => {
    const {
      match: {
        params: { view, id },
      },
      location: { search },
      t,
    } = this.props;
    const { incident, investigation, review, actions, users, formLabels, loading, queryParams } = this.state;
    const { INCIDENT, INVESTIGATION, REVIEW, ACTION, EXPORT_PDF, EVENT_TYPE_SWAP } = MAIN_URLS;
    switch (true) {
      case INCIDENT.includes(view):
        return <HasIncidentDetails incident={incident} formLabels={formLabels} />;
      case INVESTIGATION.includes(view) || REVIEW.includes(view):
        if (!hasPermission()) {
          Notification.error(ErrorMessages.ROLE_NO_ACCESS);
          return <Redirect to={`/events/event/${id}`} />;
        }
        if (MAIN_REPORT_TYPES.includes(incident.type) && INVESTIGATION.includes(view)) {
          return <HasInvestigationDetails incident={incident} investigation={investigation} />;
        } else if (!MAIN_REPORT_TYPES.includes(incident.type) && REVIEW.includes(view)) {
          return (
            <HasReviewDetails
              incident={incident}
              review={review}
              users={users}
              editMode={queryParams.get(REVIEW_EDIT_KEY)}
            />
          );
        } else {
          return (
            <Result
              status="warning"
              title={
                <HasText
                  content={(() => (
                    <>
                      {t('incidents.eventsOfType')}{' '}
                      <span style={{ color: COLORS.PRIMARY_BLUE }}>{t(IncidentTypeLabel[incident.type])}</span>{' '}
                      {t('incidents.canNotHaveCreated', { views: t(`incidents._${view}s`) })}
                    </>
                  ))()}
                  style={{ color: COLORS.BLACK_RGBA(0.45), fontSize: '16px' }}
                />
              }
            />
          );
        }
      case ACTION.includes(view):
        return (
          <HasActionDetails
            incident={this.state.incident}
            actions={actions}
            users={users}
            loading={loading}
            queryParam={getQueryParamValue(search)}
            {...this.props}
          />
        );
      case EXPORT_PDF.includes(view):
        return (
          <HasEventPdfExport
            id={id}
            eventType={incident.type}
            hasActions={actions.length > 0}
            hasOpenInvestigation={!isObjectEmpty(investigation)}
            hasPhotos={this.incidentHasAccessiblePhotos()}
          />
        );
      case EVENT_TYPE_SWAP.includes(view):
        return incident.hidden ? <Redirect to={`/events/event/${id}`} /> : <HasEventTypeSwap event={incident} />;
    }
  };

  componentWillUnmount() {
    IncidentService.eventTypeChanged({});
    this.subscriptions.forEach((subsciption) => subsciption.unsubscribe());
  }

  render() {
    const {
      match: {
        params: { view },
      },
      t,
    } = this.props;
    const {
      loadError,
      incident,
      photos,
      incidentAttachments,
      investigationAttachments,
      loading,
      mediaLoading,
      investigation,
      review,
      actions,
      actionAttachments,
      reviewAttachments,
    } = this.state;
    const baseIncidentContainerClass = 'grid-incident-details-container ';
    return loadError ? (
      <div className="d-flex flex-column align-items-center h-100 shadow-sm has-container-tile">
        <Result
          status="warning"
          title={t('incidents.eventNotFound')}
          subTitle={t('incidents.eventNotFoundDetailed')}
          extra={
            <Link to="/events">
              <HasButton type="primary">{t('incidents.backToEventsList')}</HasButton>
            </Link>
          }
          style={{ marginTop: '80px' }}
        />
      </div>
    ) : (
      <div className={baseIncidentContainerClass.concat(incident.hidden ? 'has-hidden-incident-page' : '')}>
        <div className="incidentDetails1">
          <div className={'shadow-sm flex-grow-1 overflow-auto d-flex flex-column has-container-tile'}>
            <Skeleton active loading={loading}>
              <HasReportingOwnerDescription incident={incident} />
            </Skeleton>
          </div>
        </div>
        <div className="incidentDetails2">
          <div className={'shadow-sm flex-grow-1 overflow-auto d-flex flex-column has-container-tile'}>
            <Skeleton active loading={loading}>
              {incident.type && MAIN_REPORT_TYPES.concat(IncidentType.HOUSE_KEEPING).includes(incident.type) ? (
                <HasInvolvedPartyDescription incident={incident} />
              ) : (
                <HasInvolvedPartiesDescription incident={incident} />
              )}
            </Skeleton>
          </div>
        </div>
        <div className="incidentDetails3">
          <div className="shadow-sm flex-grow-1 overflow-auto d-flex flex-column has-container-tile">
            <Skeleton active loading={loading}>
              <HasIncidentLocationDescription incident={incident} />
            </Skeleton>
          </div>
        </div>
        <div className="tabs">
          <div className="shadow-sm d-flex flex-column flex-grow-1 has-container-tile">
            {loading ? (
              <HasSpinner size="large" />
            ) : (
              <>
                <div className="d-flex flex-row">
                  <HasHorizontalMenu
                    menuItems={this.buildMenuConfig(incident)}
                    selectedKeys={[this.props.location.pathname]}
                    style={{ margin: '0px -10px 20px -10px' }}
                    className="d-flex flex-grow-1 flex-shrink-0 justify-content-around bg-transparent"
                  />
                  <HasHorizontalMenu
                    menuItems={this.getHorizontalMenuItems()}
                    selectedKeys={[this.props.location.pathname]}
                    style={{ margin: '0px -10px 20px -10px', paddingRight: '10px' }}
                    className="d-flex flex-shrink-0 justify-content-end bg-transparent"
                  />
                </div>
                {this.getEventInformationBanners()}
                {this.getRiskMatrixBanner()}
                <div
                  className="d-flex flex-column flex-grow-1 mt-2 overflow-y-auto "
                  style={{ margin: '0 -11px', padding: '10px' }}
                >
                  {this.getViewComponent()}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="imageSection">
          <HasIncidentMedia
            incident={incident}
            investigation={investigation}
            review={review}
            actions={actions}
            photos={photos}
            incidentAttachments={incidentAttachments}
            investigationAttachments={investigationAttachments}
            actionAttachments={actionAttachments}
            reviewAttachments={reviewAttachments}
            currentView={view}
            loading={mediaLoading}
          />
        </div>
      </div>
    );
  }
}

HasIncidentPageWrapper.contextType = PermissionsContext;

export default withTranslation()(HasIncidentPageWrapper);
