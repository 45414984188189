import { CheckCircleOutlined, MoreOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { isEqual, isNull } from 'lodash';
import React, { ReactNode } from 'react';
import { WithTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { Company, IncidentType, IncidentTypeLabel, UserRole } from '../../../models';
import { CompanyService, DivisionService, IncidentService, UserService } from '../../../services';
import { COLORS, DATE_LOCALE, MAIN_URLS } from '../../../shared';
import { getUserRole, hasPermission, IncidentIcons, toUpperCaseAndRemoveUnderscore } from '../../../utils';
import { HasImage, HasText } from '../../atoms';
import { HasDropdownGroup, HasSingleDropdownGroup } from '../../molecules';
import HasLanguageFlagPopover from '../../molecules/data-entry/language-flag.popover';
import HasHeaderCompanyInfo from './header.company.info';
import './header.scss';

interface HeaderProps {
  header: React.ComponentClass<BasicProps>;
}

interface HeaderState {
  companies: Company[];
  showExtra: boolean;
  eventType?: IncidentType;
  eventId?: number;
}

class HasHeader extends React.Component<RouteComponentProps & HeaderProps & WithTranslation, HeaderState> {
  state: HeaderState = { companies: [], showExtra: false };

  private companyUpdated: Subscription = CompanyService.getCompanyChangedListener().subscribe(() =>
    CompanyService.getUserVisibleCompanies().then((response) => this.setState({ companies: response.data }))
  );

  private divisionUpdated: Subscription = DivisionService.getDivisonChangedListener().subscribe(() =>
    CompanyService.getUserVisibleCompanies().then((response) => this.setState({ companies: response.data }))
  );

  private eventTypeUpdated: Subscription = IncidentService.getEventTypeChangedListener().subscribe((payload) =>
    this.setState({ eventType: payload.eventType, eventId: payload.eventId })
  );

  private userAccessUpdated: Subscription = UserService.getCompanyDataViewChangedListener().subscribe(() =>
    CompanyService.getUserVisibleCompanies().then((response) => this.setState({ companies: response.data }))
  );

  componentDidMount() {
    CompanyService.getUserVisibleCompanies().then((response) => this.setState({ companies: response.data }));
  }

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps & HeaderProps>, nextState: HeaderState): boolean {
    if (MAIN_URLS.LOGIN.includes(nextProps.location.pathname.replace('/', ''))) {
      return false;
    }
    if (!isEqual(this.props, nextProps)) {
      return true;
    }
    if (!isEqual(this.state.companies, nextState.companies)) {
      return true;
    }
    if (this.state.eventType !== nextState.eventType) {
      return true;
    }
    return true;
  }

  getCurrentPath = (): string[] => this.props.location.pathname.split('/').filter((item) => item !== '' && !+item);

  getHeaderTitle = (): JSX.Element => {
    const { t } = this.props;
    const currentPath = this.getCurrentPath();
    let content: JSX.Element;
    if (currentPath.every((item) => MAIN_URLS.NOT_FOUND.includes(item))) {
      content = (
        <>
          <QuestionCircleOutlined
            style={{ verticalAlign: 'middle', fontSize: '24px', marginRight: '10px', color: COLORS.NEW_ORANGE }}
          />
          <HasText content={t('header.pageNotFound')} strong />
        </>
      );
    }
    switch (true) {
      case currentPath.every((item) => MAIN_URLS.INCIDENT_LIST.includes(item)):
        content = (
          <div>
            <HasImage
              alt={t('header.incidentList')}
              src={require('../../../assets/images/incident_list_logo_2_orange.png')}
              style={{ marginRight: '8px', height: '30px', objectFit: 'contain' }}
            />
            <HasText content={t('header.eventsList')} strong style={{ color: COLORS.NEW_ORANGE }} />
          </div>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.INCIDENT.includes(item)):
      case currentPath.every((item) => MAIN_URLS.INVESTIGATION.includes(item)):
      case currentPath.every((item) => MAIN_URLS.REVIEW.includes(item)):
      case currentPath.every((item) => MAIN_URLS.ACTION.includes(item)):
      case currentPath.every((item) => MAIN_URLS.EXPORT_PDF.includes(item)):
      case currentPath.every((item) => MAIN_URLS.EVENT_TYPE_SWAP.includes(item)):
        content = (
          <div className="d-inline-flex align-items-center">
            {this.state.eventType ? (
              <HasImage
                src={IncidentIcons[this.state.eventType]}
                alt={toUpperCaseAndRemoveUnderscore(this.state.eventType)}
                style={{ height: '40px', marginRight: '8px', objectFit: 'contain' }}
                className="has-avatar"
              />
            ) : null}
            <HasText
              content={
                this.state.eventType
                  ? `#${this.state.eventId} ${t('header.eventDetails', {
                      eventType: t(IncidentTypeLabel[this.state.eventType]).toUpperCase(),
                    })}`
                  : '...'
              }
              strong
              style={{ color: COLORS.NEW_ORANGE }}
            />
          </div>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.CHART.includes(item)):
        content = (
          <>
            <HasText content={t('charts.header').toUpperCase()} strong style={{ color: COLORS.NEW_ORANGE }} />
          </>
        );
        break;
      case currentPath.some((item) => MAIN_URLS.SETTINGS.includes(item)):
        content = (
          <>
            <HasText content={t('sidebar.settings').toUpperCase()} strong style={{ color: COLORS.NEW_ORANGE }} />
          </>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.COMPANIES.includes(item)):
        content = (
          <>
            <HasText content={t('sidebar.companies').toUpperCase()} strong style={{ color: COLORS.NEW_ORANGE }} />
          </>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.CONTACT.includes(item)):
        content = (
          <Col>
            <HasText content={t('shared.contact').toUpperCase()} strong style={{ color: COLORS.NEW_ORANGE }} />
          </Col>
        );
        break;
      case currentPath.some((item) => MAIN_URLS.TEAMS.includes(item)):
        content = (
          <>
            <HasText content={t('sidebar.teams').toUpperCase()} strong style={{ color: COLORS.NEW_ORANGE }} />
          </>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.ACTION_LIST.includes(item)):
        const title =
          getUserRole() === UserRole.EMPLOYEE ||
          getUserRole() === UserRole.LIMITED_EMPLOYEE_DIVISION ||
          getUserRole() === UserRole.LIMITED_EMPLOYEE_PERSONAL
            ? t('action.myActions').toUpperCase()
            : t('header.actionList');
        content = (
          <Col style={{ display: 'flex', flexDirection: 'row', lineHeight: 'normal', alignItems: 'center' }}>
            <CheckCircleOutlined
              style={{ color: COLORS.NEW_ORANGE, marginRight: '12px', fontSize: '30px', objectFit: 'contain' }}
            />
            <HasText content={title} strong style={{ color: COLORS.NEW_ORANGE }} />
          </Col>
        );
        break;
      case currentPath.every((item) => MAIN_URLS.DASHBOARD.includes(item)):
      default:
        content = (
          <>
            <HasText content={t('header.hasDashboard')} strong style={{ color: COLORS.NEW_ORANGE }} />
            <HasText content={t('header.asOfDate', { date: new Date().toLocaleDateString(DATE_LOCALE) })} />
          </>
        );
    }
    return <div className="header-title-container">{content}</div>;
  };

  getHeaderDropdownGroup = (): ReactNode => {
    const { companies } = this.state;
    const currentPath = this.getCurrentPath();
    if (currentPath.every((item) => MAIN_URLS.NOT_FOUND.includes(item))) {
      return null;
    }
    const userRole = getUserRole();
    if (hasPermission()) {
      switch (true) {
        case currentPath.every((item) => MAIN_URLS.DASHBOARD.includes(item)):
        case currentPath.every((item) => MAIN_URLS.INCIDENT_LIST.includes(item)):
        case currentPath.every((item) => MAIN_URLS.ACTION_LIST.includes(item)):
          return <HasDropdownGroup companies={companies} />;
        case currentPath.some((item) => MAIN_URLS.SETTINGS.includes(item)):
        case currentPath.some((item) => MAIN_URLS.TEAMS.includes(item)):
          return <HasSingleDropdownGroup companies={companies} hideDivisionsDropdown />;
        case currentPath.every((item) => MAIN_URLS.INCIDENT.includes(item)):
        case currentPath.every((item) => MAIN_URLS.INVESTIGATION.includes(item)):
        case currentPath.every((item) => MAIN_URLS.REVIEW.includes(item)):
        case currentPath.every((item) => MAIN_URLS.ACTION.includes(item)):
        case currentPath.every((item) => MAIN_URLS.COMPANIES.includes(item)):
        case currentPath.every((item) => MAIN_URLS.PRIVACY_POLICY.includes(item)):
        case currentPath.every((item) => MAIN_URLS.CONTACT.includes(item)):
        case currentPath.every((item) => MAIN_URLS.EXPORT_PDF.includes(item)):
        case currentPath.every((item) => MAIN_URLS.EVENT_TYPE_SWAP.includes(item)):
        default:
          return null;
      }
    } else if (userRole) {
      return <HasHeaderCompanyInfo companies={companies} />;
    }
  };

  componentWillUnmount() {
    this.companyUpdated.unsubscribe();
    this.divisionUpdated.unsubscribe();
    this.eventTypeUpdated.unsubscribe();
    this.userAccessUpdated.unsubscribe();
  }

  render() {
    const { header: Header } = this.props;
    const { showExtra } = this.state;
    const dropdownGroup = this.getHeaderDropdownGroup();
    return (
      <Header className={'has-header shadow-sm '.concat(showExtra ? 'show-extra' : '')}>
        <div className="d-flex flex-row justify-content-between">
          {this.getHeaderTitle()}
          <div className="header-modal-toggle">
            <MoreOutlined
              style={{ fontSize: 18 }}
              onClick={() => this.setState(({ showExtra }) => ({ showExtra: !showExtra }))}
            />
          </div>
        </div>

        <div className="header-non-title-container">
          {!isNull(dropdownGroup) && this.state.companies.length > 0 && (
            <div className="header-company-selector">{dropdownGroup}</div>
          )}
          <div className="header-language-picker-and-user-profile-container">
            <div className="header-language-picker">
              <HasLanguageFlagPopover />
            </div>
            <Link className="header-user-profile" to={{ pathname: '/user' }}>
              <UserOutlined style={{ verticalAlign: 'middle', fontSize: '24px', color: COLORS.NEW_ORANGE }} />
            </Link>
          </div>
        </div>
      </Header>
    );
  }
}

export default HasHeader;
